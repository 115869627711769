import React, { useState, useEffect } from 'react'

import axios from '@/utils/axios'


const CffMembershipEntry = ({name, value}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [membership, setMembership] = useState(null);
  const [loading, setLoading] = useState(false);

  const isValidCffMembershipNumber = (v) => ( v[0] === 'C' && v[3] === "-" && (v.length === 8 || v.length === 9));

  const search = (v) => {
    if( !isValidCffMembershipNumber(v) ) {
      setMembership(null);
      return;
    }
    const formData = new FormData();
    formData.append("cff_id", v);
    setMembership(null);
    setLoading(true);

    axios.post(`/search_cff.json`, formData).then((res) => {
      setMembership(res.data);
      setLoading(false);
    }).catch(() => setLoading(false))
  }

  const onChange = (e) => {
    setCurrentValue(e.target.value);
    search(e.target.value);
  }

  useEffect(() => {
    if(currentValue && isValidCffMembershipNumber(currentValue)) search(currentValue)
  }, []);
  
  return (
    <div className="w-100 d-flex flex-column">
      <input 
        name={name} 
        type="text"
        value={currentValue} 
        className="form-control" 
        placeholder="Canadian Fencing Member #..." 
        onChange={onChange}
        pattern="C{1}[0-9]{2}\-[0-9]{4,5}"
        style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 0}}
      />
      <span className="input-group-text w-100" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
        {loading && (
          <><i className="far fa-spin fa-spinner-third"></i>&nbsp;Loading...</>
        )}
        {membership && (
          <><i className="fas fa-check"></i>&nbsp;{membership.first_name} {membership.last_name} ({membership.club || "N/A"})</>
        )}
        {(!loading && !membership) && (
          <><i className="fas fa-times"></i>&nbsp;Invalid Membership/Number</>
        )}
      </span>
    </div>
  );
}

export default CffMembershipEntry;