import React, { useState, useEffect, useRef } from 'react'

const LocationFinder = ({lat, lng, value, city, maxRadius}) => {
  const inputRef = useRef();
  const formRef = useRef();
  const [inputValue, setInputValue] = useState(city);
  const [inputRadius, setInputRadius] = useState(maxRadius || "");
  const [showConfig, setShowConfig] = useState(false);

  useEffect(() => {
    if(!lat || !lng){
      if (navigator.geolocation) {
        navigator.geolocation?.getCurrentPosition((position) => {
          if (position?.coords?.latitude && position?.coords?.longitude) {
            window.location = window.location + `?ul=${position.coords.latitude},${position.coords.longitude}`
          }
        }, (err) => console.error(err) );
      }
    }
  }, [])

  return (
    <form ref={formRef} action="/" method="get" className="d-flex flex-column align-items-center  mt-3 mb-5">
      <h2 className="text-center font-weight-bold">
        Find Fencing Events near&nbsp;
        <div style={{ display: 'inline', width: 100, borderBottom: '2px solid #000', cursor: 'pointer', textWrap: 'nowrap'}}>
          <input 
            ref={inputRef}
            type="text" 
            name="ul" 
            value={inputValue} 
            onChange={e => setInputValue(e.target.value)}
            style={{ width: 240, background: 'transparent', border: 0, outline: 'none', fontWeight: 700 }}
            placeholder="Pick a city..." 
            autoComplete="off"
          /> 
          <i className="fa fa-fw fa-pencil" style={{ fontSize: 20 }} onClick={() => {
            setInputValue('');
            inputRef?.current?.focus()
          }} />
        </div>
        <i className="fa fa-fw fa-cog cursor-pointer ml-2" style={{ fontSize: 20 }} onClick={() => setShowConfig(!showConfig)} />
      </h2>
      <div className={showConfig ? "d-flex align-items-center" : "d-none"}>
        <span className="text-nowrap text-bold">Max Search Radius:</span>
        <select className="form-control ml-2" name="max_radius" value={inputRadius} onChange={e => formRef?.current?.submit()}>
          <option value="">Not Set</option>
          <option value="10">10 Miles</option>
          <option value="25">25 Miles</option>
          <option value="50">50 Miles</option>
          <option value="100">100 Miles</option>
          <option value="200">200 Miles</option>
          <option value="300">300 Miles</option>
        </select>
      </div>
    </form>
  );
}

export default LocationFinder;


